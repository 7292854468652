import React, { useState, useEffect, useRef, useMemo } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { GatsbyImage } from "gatsby-plugin-image"

import AnimationWrapper from "./animationWrapper"
import Container from "./container"
import CustomAniLink from "./customAniLink"
import GoogleMapComponent from "../components/googleMap"
import Title from "./title"

import "./contactMapInteractive.css"

const ContactMapInteractive = ( { contactData, contactProject, regions } ) => {

  const data = useStaticQuery(
    graphql`
      query {
        kontakt: mdx(frontmatter: { slug: { eq: "kontakt" }, type: { eq: "page" } }) {
          frontmatter{
            map_description
          }
        }
      }
    `
  )

  const [contactResultsPopup, showContactResults] = useState(false);
  const [dynamicPopupTitle, setPopupTitle] = useState(false);
  const [activeRegion, setActiveRegion] = useState({type:'', voivodship:'', powiat:'', miejscowosc: ''})
  const [regionContactData, setRegionContactData] = useState('');

  const [voivodships, setVoivodships] = useState([]);
  const [regionsGrouped, setRegionsGrouped] = useState([]);
  const [splitRegions, setSplitRegions] = useState([]);

  const svgRef = useRef(null);
  const pathRefs = useRef({}); // Stores refs for each path
  const [textPositions, setTextPositions] = useState({}); // Stores computed positions for city labels
  const [viewBox, setViewBox] = useState("0 0 800 600"); // Default viewBox

  const mappedVoivodships = [
    "zachodniopomorskie",
    // "pomorskie", disabled because of miejscowosci split
    "warmińsko-mazurskie",
    "kujawsko-pomorskie",
    "wielkopolskie",
    "mazowieckie",
    "łódzkie",
    "małopolskie",
    // to be mapped:
    // "podlaskie",
    // "lubelskie",
    // "podkarpackie",
    // "śląskie",
    // "opolskie",
    // "dolnośląskie",
    // "świętokrzyskie",
    // "lubuskie",
  ];

  const mapPins = {
    "czamaninek": {
      name: "Czamaninek",
      pinX: "453",
      pinY: "385",
      textX: "453",
      textY: "360",
    },
    "biuro-torun": {
      name: "Biuro Handlowe Toruń",
      pinX: "445",
      pinY: "328",
      textX: "440",
      textY: "303",
    },
    "keramzyt-dom": {
      name: "Keramzyt Dom Karol Dżurko",
      pinX: "180",
      pinY: "140",
      textX: "195",
      textY: "170",
    },
    "biuro-radom": {
      name: "Biuro Handlowe Radom",
      pinX: "702",
      pinY: "570",
      textX: "702",
      textY: "545",
    }
  };

  const [tooltip, setTooltip] = useState({
    visible: false,
    content: '',
    x: 0,
    y: 0,
  });

  useMemo(() => {
    const voivodshipData = [];
    regions.forEach(function(voivodship){
      voivodshipData[voivodship.frontmatter.title] = [];
      voivodshipData[voivodship.frontmatter.title].name = voivodship.frontmatter.title;
      voivodshipData[voivodship.frontmatter.title].split = voivodship.frontmatter.split;
      // Load full contact data from slug
      contactData.nodes.forEach(function(contact){
        if(contact.frontmatter.slug === voivodship.frontmatter.contact_sales){
          voivodshipData[voivodship.frontmatter.title].contact_sales = contact.frontmatter;
        }
        if(contact.frontmatter.slug === voivodship.frontmatter.contact_service){
          voivodshipData[voivodship.frontmatter.title].contact_service = contact.frontmatter;
        }
      });
      // Push powiaty data to new object
      voivodshipData[voivodship.frontmatter.title].powiaty = [];
      voivodship.frontmatter.powiaty.forEach(function(powiat){
        voivodshipData[voivodship.frontmatter.title].powiaty.push({
          name: powiat.name,
          split: powiat.split,
          contact_sales: powiat.contact_sales,
          contact_service: powiat.contact_service,
          miejscowosci: powiat.miejscowosci,
          svgd: powiat.svgd,
          city: powiat.city
        });
      });
    });
    // Initialize voivodships object
    setVoivodships(voivodshipData);

    // Group splited regions by contactData
    const regionsGroupedData = {};
    Object.values(voivodshipData).forEach(function(voivodship) {
      regionsGroupedData[voivodship.name] = groupSplitSubregions(voivodship.powiaty);
    });
    // Initialize regionsGrouped object
    setRegionsGrouped(regionsGroupedData);

    const regionSplitList = {};
    Object.values(voivodshipData).forEach(function(voivodship) {
      regionSplitList[voivodship.name] = voivodship.split;
    });
    // Initialize splitRegions object
    setSplitRegions(regionSplitList);

    function groupSplitSubregions(powiaty) {
      const grouped = {};
      powiaty.forEach(powiat => {
        let contact_sales_data = null;
        let contact_service_data = null;
        // Find correct contact data for powiat
        contactData.nodes.forEach(contact => {
          if (contact.frontmatter.slug === powiat.contact_sales) {
            contact_sales_data = contact.frontmatter;
          }
          if (contact.frontmatter.slug === powiat.contact_service) {
            contact_service_data = contact.frontmatter;
          }
        });
        // Initialize the group for powiat if not already created
        if (!grouped[powiat.contact_sales]) {
          grouped[powiat.contact_sales] = {
            contact_sales: contact_sales_data,
            contact_service: contact_service_data,
            regiony: []
          };
        }
        // Add powiat region data
        grouped[powiat.contact_sales].regiony.push({
          name: powiat.name,
          split: powiat.split,
          type: 'powiat',
          contact_sales: contact_sales_data,
          contact_service: contact_service_data,
          svgd: powiat.svgd,
          city: powiat.city
        });
        // Handle split miejscowosci for this powiat
        if (powiat.split) {
          powiat.miejscowosci.forEach(miejscowosc => {
            let miejscowosc_sales_data = null;
            let miejscowosc_service_data = null;
            // Find correct contact data for miejscowosc
            contactData.nodes.forEach(contact => {
              if (contact.frontmatter.slug === miejscowosc.contact_sales) {
                miejscowosc_sales_data = contact.frontmatter;
              }
              if (contact.frontmatter.slug === miejscowosc.contact_service) {
                miejscowosc_service_data = contact.frontmatter;
              }
            });
            // Initialize the group for miejscowosc if not already created
            if (!grouped[miejscowosc.contact_sales]) {
              grouped[miejscowosc.contact_sales] = {
                contact_sales: miejscowosc_sales_data,
                contact_service: miejscowosc_service_data,
                regiony: []
              };
            }
            // Add miejscowosc region data
            grouped[miejscowosc.contact_sales].regiony.push({
              name: miejscowosc.name,
              type: 'miejscowosc',
              contact_sales: miejscowosc_sales_data,
              contact_service: miejscowosc_service_data
            });
          });
        }
      });
      // Ensure keyname & region count are correctly assigned
      Object.values(grouped).forEach(contact => {
        contact.keyname = contact.regiony[0]?.name || 'unknown';
        contact.regionCount = contact.regiony.length;
      });
      Object.values(grouped).forEach(function(contact) {
        // Set keyname prop from first region
        contact.keyname = contact.regiony[0].name;
        // Count number of regions for each grouped contact
        contact.regionCount = contact.regiony.length;
      });
      // Check if there are exacly two subregions
      if (Object.keys(grouped).length === 2){
        // Initialize variables to store the contact with the highest regionCount
        let contactWithHighestRegionCount = null;
        let highestRegionCount = 0;
        // Iterate over each key-value pair in the contactData object
        for (const key in grouped) {
          if (grouped.hasOwnProperty(key)) {
            const contactName = grouped[key];
            // Check if the current contact has a higher regionCount (higher than 3)
            if (contactName.regionCount > highestRegionCount && contactName.regionCount > 3) {
              highestRegionCount = contactName.regionCount;
              contactWithHighestRegionCount = contactName;
            }
          }
        }
        // Set regionCount to 'highest' on bigger region
        if (contactWithHighestRegionCount) {
          contactWithHighestRegionCount.regionCount = 'highest';
        };
      };

      Object.values(grouped).forEach(function(contact) {
        // Initialize variables to store regions data
       const powiaty = [];
       const miejscowosci = [];
       let regionList;
       // Load full contact data from slug
       contact.regiony.forEach(region => {
         if (region.type === 'powiat'){
           powiaty.push(region.name);
         } else if (region.type === 'miejscowosc'){
           miejscowosci.push(region.name);
         }
       });
       // Setup regionList string for region buttons
       if (contact.regionCount === 'highest'){
         regionList = 'Pozostałe';
       } else {
         switch (powiaty.length){
           case 0:
             regionList = '';
             break;
           case 1:
             regionList = 'Powiat ' + powiaty[0] + ' <br>';
             break;
           default:
             regionList = 'Powiaty: ' + powiaty.join(', ') + ' <br>';
             break;
         }
         switch (miejscowosci.length){
           case 0:
             regionList += '';
             break;
           case 1:
             regionList += miejscowosci[0];
             break;
           default:
             regionList += 'Miejscowości: ' + miejscowosci.join(', ');
             break;
         }
       }
       contact.regionList = regionList;
     });
      return grouped;
    }

  }, [contactData, regions]);


  useEffect(() => {
    const getRegionContactData = (activeRegion) => {
      const regionContactDataResult = {};

      if (activeRegion.voivodship) {
        if (voivodships[activeRegion.voivodship].split === false) {
          // Direct access when there's no split
          regionContactDataResult.contact_sales = voivodships[activeRegion.voivodship].contact_sales;
          regionContactDataResult.contact_service = voivodships[activeRegion.voivodship].contact_service;
        } else {
          // Iterating over the categories in regionsGrouped
          Object.values(regionsGrouped[activeRegion.voivodship] ?? {}).forEach(function(contact) {
            contact.regiony.forEach(function(region) {
              // Logic for matching based on region type and name
              if (activeRegion.type === 'powiat' && region.name === activeRegion.powiat) {
                regionContactDataResult.contact_sales = contact.contact_sales;
                regionContactDataResult.contact_service = contact.contact_service;
              } else if (activeRegion.type === 'miejscowosc' && region.name === activeRegion.miejscowosc) {
                regionContactDataResult.contact_sales = contact.contact_sales;
                regionContactDataResult.contact_service = contact.contact_service;
              }
            });
          });
        }
      }
      return regionContactDataResult;
    };
    // Set regionContactData
    const regionContactDataResult = getRegionContactData(activeRegion);
    setRegionContactData(regionContactDataResult);

    if (svgRef.current) {
      const paths = svgRef.current.querySelectorAll("path");
      if (paths.length > 0) {
        let minX = Infinity,
          minY = Infinity,
          maxX = -Infinity,
          maxY = -Infinity;

        paths.forEach((path) => {
          const bbox = path.getBBox();
          minX = Math.min(minX, bbox.x);
          minY = Math.min(minY, bbox.y);
          maxX = Math.max(maxX, bbox.x + bbox.width);
          maxY = Math.max(maxY, bbox.y + bbox.height);
        });

        // Calculate width and height
        const width = maxX - minX;
        const height = maxY - minY;

        setViewBox(`${minX} ${minY} ${width} ${height}`);
      }
    }

    if (svgRef.current) {
      const newPositions = {};

      Object.entries(pathRefs.current).forEach(([regionName, path]) => {
        if (path) {
          const bbox = path.getBBox();
          newPositions[regionName] = {
            x: bbox.x + bbox.width / 2,
            y: bbox.y + bbox.height / 2,
          };
        }
      });

      setTextPositions(newPositions);
    }

  }, [activeRegion, voivodships, regionsGrouped]);

  function getRegionList(voivodship) {
    // Get activeRegion data and create return values array
    const regionData = regionsGrouped[voivodship];
    const regions = [];
    Object.values(regionData).forEach(function(contact) {
      regions.push({
        name: contact.keyname,
        regionList: contact.regionList
      })
    });
    // Sort 'pozostałe' region as last one
    regions.sort((a, b) => {
      if (a.regionList === 'Pozostałe' && b.regionList !== 'Pozostałe') {
        return 1; // `a` should be placed after `b`
      } else if (a.regionList !== 'Pozostałe' && b.regionList === 'Pozostałe') {
        return -1; // `a` should be placed before `b`
      } else {
        return 0; // No sorting needed between `a` and `b`
      }
    });
    return regions;
  }

  // Handle mouse enter to show tooltip
  const handleMouseEnter = (e, region) => {
    // Check if it's a touch device and do nothing
    if ("ontouchstart" in window || navigator.maxTouchPoints > 0) {
      return;
    }
    // Set tooltip content based on region.city value
    const tooltipContent = region.city ? region.name : `${region.type} ${region.name}`;
    setTooltip({
      visible: true,
      content: tooltipContent, // Show only region.name if city = true
      x: e.clientX,
      y: e.clientY + 60, // Adjust position if needed
    });
  };

  // Handle mouse move to update tooltip position
  const handleMouseMove = (e) => {
    if (tooltip.visible) {
      setTooltip((prevTooltip) => ({
        ...prevTooltip,
        x: e.clientX, // Follow the horizontal cursor movement
        y: e.clientY + 60, // Keep the tooltip above the cursor
      }));
    }
  };

  // Handle mouse leave to hide tooltip
  const handleMouseLeave = () => {
    setTooltip((prevTooltip) => ({
      ...prevTooltip,
      visible: false,
    }));
  };

  // console.log('activeRegion', activeRegion);
  // console.log('splitRegionsGrouped[kujawsko-pomorskie]', splitRegionsGrouped['kujawsko-pomorskie']);
  // console.log('voivodships', voivodships);
  // console.log('regions', regions);

  return (

    <section id="contact-map-interactive-section" className="page-section">

      <AnimationWrapper>
        <Container className="contact-map-interactive-container">
          <div className="map-description-wrapper">
            <Title className="contact-map-title" title="Interaktywna mapa kontaktowa" subtitle="" headingTag="h2" temp={false} />

              {data.kontakt.frontmatter.map_description
                ?
                <p className="section-description" dangerouslySetInnerHTML={{ __html: data.kontakt.frontmatter.map_description }} />
                :
                <>
                <p className="section-description">Najedź kursorem i kliknij na wybrany obszar aby zobaczyć dane kontaktowe osób odpowiedzialnych za wybrany region.</p>
                <p className="section-description">Możesz też wpisać lokalizację w poniższe pole aby odnaleźć ją na mapie i przyporządkować do regionu.</p>
                </>
              }
            <fieldset className="searchContainer">
                <GoogleMapComponent regions={regionsGrouped} setActiveRegion={setActiveRegion} setPopupTitle={setPopupTitle} showContactResults={showContactResults} />
            </fieldset>

          </div>

          <div className="map-interactive-wrapper">
            <div className="mapContainer">
              <svg className="map" id="map" baseprofile="tiny" fill="#7c7c7c" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" version="1.2" viewBox="0 0 1000 950" xmlns="http://www.w3.org/2000/svg">

              {regions.map((voivodship) => (
                <path
                  key={voivodship.frontmatter.title}
                  id={voivodship.frontmatter.title}
                  name={voivodship.frontmatter.title}
                  className={`voivodship ${activeRegion.voivodship === voivodship.frontmatter.title ? "active" : "nonactive"}`}
                  d={voivodship.frontmatter.svgd}
                  onClick={() => {
                    setActiveRegion({ type: 'voivodship', voivodship: voivodship.frontmatter.title, powiat: '', miejscowosc: '' });
                    showContactResults(true);
                    setPopupTitle(true);
                  }}
                />
              ))}

              {Object.values(mapPins).map((pin, index) => (
                <g key={index}>
                  <text
                    x={pin.textX}
                    y={pin.textY}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fill="#000"
                    pointerEvents="none"
                    className="region-name"
                  >
                    {pin.name}
                  </text>
                  <text
                    x={pin.pinX}
                    y={pin.pinY}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fill="#000"
                    pointerEvents="none"
                    className="region-pin"
                  >
                    •
                  </text>
                </g>
              ))}

              </svg>
            </div>
          </div>
        </Container>
      </AnimationWrapper>

      <div
        className={`popup-wrapper contact-results-popup ${contactResultsPopup ? "show" : ""}`} role="presentation"
        onClick={() => {showContactResults(false); setTimeout(() => {setActiveRegion({type: '', voivodship: '', powiat: '', miejscowosc: ''})}, 500);}}
      >
        <div
          className={`popup ${activeRegion.voivodship} ${activeRegion.powiat}`}
          role="presentation" onClick={prevent(()=>console.log("Child Element!"))}
        >
          <button
            className="close-popup-btn"
            onClick={() => {showContactResults(false); setTimeout(() => {setActiveRegion({type: '', voivodship: '', powiat: '', miejscowosc: ''})}, 500);}}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>

          <div className="popup-content-wrapper">
            {dynamicPopupTitle && (
              <Title
                className={`map-popup-title ${!dynamicPopupTitle ? "no-capitalize" : ""}`}
                title={
                  dynamicPopupTitle
                    ? activeRegion.type === "voivodship" ? `Województwo ${activeRegion.voivodship}`
                    : activeRegion.type === "powiat" ? `Powiat ${activeRegion.powiat}`
                    : activeRegion.type === "miejscowosc" ? activeRegion.miejscowosc
                    : ""
                    : "Zapraszamy do kontaktu"
                }
              />
            )}

            <div className="voivodships-wrapper">

              {splitRegions[activeRegion.voivodship] ?
                (mappedVoivodships.includes(activeRegion.voivodship) ? (
                  <div className={`choose-powiat ${activeRegion.type === 'voivodship' ? "show" : ""}`}>
                    <div className="contact-type section-subtitle">Wybierz region:</div>
                    <div className="voivodship-map-container">
                    <svg
                      ref={svgRef}
                      viewBox={viewBox}
                      className={`${activeRegion.voivodship}-map`}
                      id="voivodship-split-map"
                      fill="#7c7c7c"
                      stroke="#ffffff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="0.5"
                      preserveAspectRatio="xMidYMid meet"
                      width="100%"
                      height="100%"
                      onMouseMove={handleMouseMove}
                    >
                      {Object.values(regionsGrouped[activeRegion.voivodship] ?? {})
                        .flatMap((category, categoryIndex) =>
                          category.regiony
                            .filter(region => region.type === 'powiat')
                            .map((region) => ({ ...region, groupIndex: categoryIndex }))
                        )
                        .sort((a, b) => (a.city === b.city ? 0 : a.city ? 1 : -1))
                        .map((region) => {
                          const contactName = region.contact_sales?.slug || region.contact_service?.slug;
                          return (
                            <g key={region.name}>
                              {/* Store ref for each path */}
                              <path
                                ref={(el) => (pathRefs.current[region.name] = el)}
                                id={region.name}
                                className={`${region.type} ${contactName} region-${region.groupIndex}`}
                                onClick={() => {
                                  setActiveRegion({
                                    type: region.type,
                                    voivodship: activeRegion.voivodship,
                                    powiat: region.name,
                                    miejscowosc: '',
                                  });
                                }}
                                onMouseEnter={(e) => handleMouseEnter(e, region)}
                                onMouseLeave={handleMouseLeave}
                                d={region.svgd}
                              />

                              {/* Render text if region is a city and position is available */}
                              {region.city && textPositions[region.name] && (
                                <text
                                  x={textPositions[region.name].x}
                                  y={textPositions[region.name].y}
                                  textAnchor="middle"
                                  alignmentBaseline="middle"
                                  fill="#000"
                                  pointerEvents="none"
                                  className="region-name"
                                >
                                  {region.name}
                                </text>
                              )}
                            </g>
                          );
                        })}
                    </svg>
                    </div>
                    <fieldset className="searchContainer">
                      <GoogleMapComponent regions={regionsGrouped} setActiveRegion={setActiveRegion} setPopupTitle={setPopupTitle} showContactResults={showContactResults} />
                    </fieldset>
                  </div>
                ) : (
                  <div className={`choose-powiat ${activeRegion.type === 'voivodship' ? "show" : ""}`}>
                    <div className="contact-type section-subtitle">Wybierz region:</div>
                    <div className="powiat-btn-container">
                      {getRegionList(activeRegion.voivodship).map((region) => (
                        <button
                          className="powiat-btn"
                          onClick={() => {
                            setActiveRegion({
                              type: 'powiat',
                              voivodship: activeRegion.voivodship,
                              powiat: region.name,
                              miejscowosc: '',
                            });
                            setPopupTitle(false);
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: region.regionList }} />
                        </button>
                      ))}
                    </div>
                  </div>
                )
              ) : null}

              {/* Region name tooltip */}
              {tooltip.visible && (
                <div
                  style={{
                    top: tooltip.y,
                    left: tooltip.x,
                  }}
                  className="region-tooltip"
                >
                  {tooltip.content}
                </div>
              )}

              <div className={`search-results-wrapper ${(!splitRegions[activeRegion.voivodship] || activeRegion.type !== 'voivodship') ? "show" : ""}`}>

                {regionContactData.contact_sales &&
                  <div className="dzial-handlowy-wrapper">
                    <div className="contact-type section-subtitle">Oferta | Wycena | Reklamacje</div>
                    <div className="search-results-container">
                      {regionContactData.contact_sales  &&
                        <div className="contact-card">
                          <div className="avatar-wrapper">
                            {regionContactData.contact_sales.avatar &&
                              <GatsbyImage
                                image={regionContactData.contact_sales.avatar.childImageSharp.gatsbyImageData}
                                alt={regionContactData.contact_sales.name}
                              />
                            }
                          </div>
                          <div className="contact-data">
                            <div className="name">{regionContactData.contact_sales.name}</div>
                            <div className="position">{regionContactData.contact_sales.position}</div>
                            {regionContactData.contact_sales.address?.street &&
                              <li><FontAwesomeIcon icon={faMapMarker} />
                                <span> {regionContactData.contact_sales.address?.street}, {regionContactData.contact_sales.address?.postal_code} {regionContactData.contact_sales.address?.locality}</span>
                              </li>
                            }
                            {regionContactData.contact_sales.phone && <div><FontAwesomeIcon icon={faPhone} /><a href={`tel:${regionContactData.contact_sales.phone}`}>{regionContactData.contact_sales.phone}</a></div>}
                            {regionContactData.contact_sales.phone_alt && <div><FontAwesomeIcon icon={faPhone} /><a href={`tel:${regionContactData.contact_sales.phone_alt}`}>{regionContactData.contact_sales.phone_alt} {regionContactData.contact_sales.phone_postfix && <span> {regionContactData.contact_sales.phone_postfix}</span>}</a>
                            </div>}
                            {regionContactData.contact_sales.email && <div><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${regionContactData.contact_sales.email}`}>{regionContactData.contact_sales.email}</a></div>}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }

                {regionContactData.contact_service &&
                  <div className="biuro-obslugi-wrapper">
                    <div className="contact-type section-subtitle">Obsługa zlecenia | Transport</div>
                    <div className="search-results-container">
                      {regionContactData.contact_service &&
                        <div className="contact-card">
                          <div className="avatar-wrapper">
                            {regionContactData.contact_service.avatar &&
                              <GatsbyImage
                                image={regionContactData.contact_service.avatar.childImageSharp.gatsbyImageData}
                                alt={regionContactData.contact_service.name}
                              />
                            }
                          </div>
                          <div className="contact-data">
                            <div className="name">{regionContactData.contact_service.name}</div>
                            <div className="position">{regionContactData.contact_service.position}</div>
                            {regionContactData.contact_service.address?.street &&
                              <li><FontAwesomeIcon icon={faMapMarker} />
                                <span> {regionContactData.contact_service.address?.street}, {regionContactData.contact_service.address?.postal_code} {regionContactData.contact_service.address?.locality}</span>
                              </li>
                            }
                            {regionContactData.contact_service.phone && <div><FontAwesomeIcon icon={faPhone} /><a href={`tel:${regionContactData.contact_service.phone}`}>{regionContactData.contact_service.phone}</a></div>}
                            {regionContactData.contact_service.phone_alt && <div><FontAwesomeIcon icon={faPhone} /><a href={`tel:${regionContactData.contact_service.phone_alt}`}>{regionContactData.contact_service.phone_alt} {regionContactData.contact_service.phone_postfix && <span> {regionContactData.contact_service.phone_postfix}</span>}</a>
                            </div>}
                            {regionContactData.contact_service.email && <div><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${regionContactData.contact_service.email}`}>{regionContactData.contact_service.email}</a></div>}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }

                {contactProject &&
                  <div className="biuro-projektowe-wrapper">
                      <div className="contact-type section-subtitle">Wsparcie techniczne | Projektowanie</div>
                      <div className="contact-department-desc-wrapper">
                        <h4 className="department-desc-title">{contactProject.frontmatter.name}</h4>
                        <ul className="contact-card not-named">
                          {contactProject.frontmatter.address?.street &&
                            <li><FontAwesomeIcon icon={faMapMarker} />
                              <span> {contactProject.frontmatter.address?.street}, {contactProject.frontmatter.address?.postal_code} {contactProject.frontmatter.address?.locality}</span>
                            </li>
                          }
                          {contactProject.frontmatter.phone && <li><FontAwesomeIcon icon={faPhone} /><a href={`tel:${contactProject.frontmatter.phone}`}> {contactProject.frontmatter.phone}</a></li>}
                          {contactProject.frontmatter.email && <li><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${contactProject.frontmatter.email}`}> {contactProject.frontmatter.email}</a></li>}
                        </ul>
                      </div>
                  </div>
                }

                <div className="contractors-wrapper">
                  <div className="contact-type section-subtitle">
                    <CustomAniLink Link="/wykonawcy">Polecani wykonawcy w Twojej okolicy</CustomAniLink>
                  </div>
                </div>

              </div>
              {/* search-results-wrapper end */}

            </div>
            {/* voivodships-wrapper end */}
          </div>
          {/* popup-content-wrapper end */}
        </div>
        {/* popup end */}
      </div>
      {/* popup-wrapper end */}

    </section>
  )
}

export function prevent(fn, defaultOnly) {
  return (e, ...params) => {
      e && e.preventDefault()
      !defaultOnly && e && e.stopPropagation()
      fn(e, ...params)
  }
}

export default ContactMapInteractive